import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { ReactComponent as Logo } from "../images/logo_sim.svg"

const Header = ({ siteTitle }) => (
  <section className="header  [ section  section--header ]">
    <div className="container">
      <header className="[ row  hidden--mobile ]  [ shell-flex-parent--inline  shell-flex-parent--end-main ]  header__header  margin-top--4">
        <div className="[ column  column--xs-2 ]">
          <Link className="logo" to="/">
            <Logo className="logo__image" />
          </Link>
        </div>
        <div className="[ column column--xs-4 ]  shell-flex-child--align-center">
          <nav
            className="[ navigation  navigation--primary ]"
            role="navigation"
          >
            <ul
              id="primary-navigation"
              className="[ list  list--unstyled ]  [ shell-flex-parent  shell-flex-parent--end-main  shell-flex-parent--center-cross ]"
            >
              <li className="navigation-item  navigation-magazine  [ line  line--border-top ]  [ shell-flex-child  shell-space-between--l-2   shell-space-between--m-2 ]  margin-bottom--2">
                <Link to="/purpose">
                  <span className="[ headline  headline--uppersmall ]  margin-top--1">
                    Purpose
                  </span>
                  <p className="[ description  description--smaller ]">
                    Motivating others to shape the future for good.
                  </p>
                  <span className="[ headline  headline--uppersmall ]">01</span>
                </Link>
              </li>
              <li className="navigation-item  navigation-magazine  [ line  line--border-top ]  [ shell-flex-child  shell-space-between--l-4   shell-space-between--m-2 ]  margin-bottom--2">
                <Link to="/magazine">
                  <span className="[ headline  headline--uppersmall ]  margin-top--1">
                    Magazine
                  </span>
                  <p className="[ description  description--smaller ]">
                    Striving to inspire and connect changemakers.
                  </p>
                  <span className="[ headline  headline--uppersmall ]">02</span>
                </Link>
              </li>
              <li className="navigation-item  navigation-magazine  [ line  line--border-top ]  [ shell-flex-child  shell-space-between--l-2   shell-space-between--m-2 ]  margin-bottom--2">
                <Link to="/about">
                  <span className="[ headline  headline--uppersmall ]  margin-top--1">
                    About
                  </span>
                  <p className="[ description  description--smaller ]">
                    Passionate people behind making it a reality.
                  </p>
                  <span className="[ headline  headline--uppersmall ]">03</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  </section>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header