/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import "../assets/fonts/fonts.scss"
import "../assets/styles/main.scss"
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Navbar from "./Navbar"
import Footer from "./footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <CookieConsent
          location="bottom"
          buttonText="Close"
          cookieName="GDPRCookieNotify"
          disableStyles={true}
          buttonClasses="submit  [ button  button--small-square ]"
          contentClasses="[ description  description--smaller ]  shell-space-around"
          containerClasses="cookie-consent  shell-flex-parent  shell-flex-parent--space-between  [ section  section--call-to-action ]  width--100  z-index--100  position--absolute"
          expires={150}
        >
          Social Impact Magazine uses cookies to allow us to better understand
          how the site is used. By continuing to use this site, you consent to
          this{" "}
          <a rel="noopener noreferrer" target="_blank" href="https://cookiesandyou.com/">
            policy
          </a>
          .
        </CookieConsent>
        <Navbar />
        <Header siteTitle={data.site.siteMetadata.title} />
        {/* page specific stuff: */}
        {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
