import React from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "../images/logo_sim.svg"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: "",
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "active",
            })
          : this.setState({
              navBarActiveClass: "",
            })
      }
    )
  }

  render() {
    return (
      <section className="mobile-navigation  [ section  section--mobile-navigation ]  hidden--desktop">
        <header className="mobile-navigation__header  [ shell-flex-parent  shell-flex-parent--end-main  shell-flex-parent--center-cross ]">
          <aside className="[ shell-flex-child  shell-space-between--l-4   shell-space-between--m-2 ]">
            <Link className="logo" to="/">
              <Logo className="logo__image" />
            </Link>
          </aside>
          <aside className="[ shell-flex-parent  shell-flex-parent--center-content  shell-space-between--l-4   shell-space-between--m-2 ]">
            <div
              id="hamburger"
              className={`navigation--mobile__hamburger ${
                this.state.navBarActiveClass
              }`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </aside>
        </header>
        <footer
          id="navMenu"
          className={`mobile-navigation__footer ${
            this.state.navBarActiveClass
          }`}
        >
          <div className="[ column  column--xs-6 ]">
            <nav
              className="[ navigation  navigation--secondary ]"
              role="navigation"
              aria-label="main-navigation"
            >
              <ul id="primary-navigation" className="[ list  list--unstyled ]">
                <li className="navigation-item  navigation-magazine  margin-bottom--2">
                  <Link to="/purpose">
                    <span className="[ headline  headline--uppersmall ]">
                      Purpose
                    </span>
                  </Link>
                </li>
                <li className="navigation-item  navigation-magazine  margin-bottom--2">
                  <Link to="/magazine">
                    <span className="[ headline  headline--uppersmall ]">
                      Magazine
                    </span>
                  </Link>
                </li>
                <li className="navigation-item  navigation-magazine  margin-bottom--2">
                  <Link to="/about">
                    <span className="[ headline  headline--uppersmall ]">
                      About
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </footer>
      </section>
    )
  }
}

export default Navbar
