import React from "react"
import { Link } from "gatsby"
import { ReactComponent as Logo } from "../images/logo_sim.svg"
import EmailCaptureForm from "../components/EmailCaptureForm"

const Footer = ({ data }) => (
  <section className="footer  [ section  section--footer ]">
    <div className="container">
      <div className="row  margin-top--8  shell-flex-parent--inline">
        <div className="[ column  column--xs-6  column--lg-2 ]  margin-bottom--5">
          <Link className="logo  margin-bottom--2" to="/">
            <Logo className="logo__image" />
          </Link>
          <div className="[ description  description--small ]  margin-right--2">
            <p>
              By increasing the awareness of these extraordinary people we want
              to encourage you to start your own next social enterprise.
            </p>
          </div>
        </div>
        <div className="[ column  column--xs-6  column--lg-2 ]  margin-bottom--5">
          <nav
            className="[ navigation  navigation--secondary ]"
            role="navigation"
          >
            <ul id="primary-navigation" className="[ list  list--unstyled ]">
              <li className="navigation-item  navigation-magazine  margin-bottom--2">
                <Link to="/magazine">
                  <span className="[ headline  headline--uppersmall ]">
                    Magazine
                  </span>
                </Link>
              </li>
              <li className="navigation-item  navigation-magazine  margin-bottom--2">
                <Link to="/purpose">
                  <span className="[ headline  headline--uppersmall ]">
                    Purpose
                  </span>
                </Link>
              </li>
              <li className="navigation-item  navigation-magazine  margin-bottom--2">
                <Link to="/about">
                  <span className="[ headline  headline--uppersmall ]">
                    About
                  </span>
                </Link>
              </li>
              <li className="navigation-item  navigation-magazine  margin-bottom--2">
                <a href="mailto:hello@socialimpactmag.com">
                  <span className="[ headline  headline--uppersmall ]">
                    hello@socialimpactmag.com
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="[ column  column--xs-6  column--lg-2 ]  margin-bottom--5">
          <div className="[ headline  headline--uppersmall ]  margin-bottom--2">
            Email updates
          </div>
          <EmailCaptureForm
            capturesize="small"
            className="email-updates-form  [ newsletter-signup  newsletter-signup--small ]  width--100  margin-bottom--0"
          />
        </div>
      </div>
    </div>
  </section>
)

export default Footer
