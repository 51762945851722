import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'


class EmailCaptureForm extends React.Component {
  constructor() {
    super()
    this.state = {
	  email: ``,
    }
  }

  // Update state each time user edits their email address
  _handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }

  // Post to MC server & handle its response
  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
    .then(result => {
      // Mailchimp always returns a 200 response
      // So we check the result for MC errors & failures
      if (result.result !== `success`) {
        this.setState({
          status: `error`,
          msg: result.msg,
        })
      } else {
        // Email address succesfully subcribed to Mailchimp
        this.setState({
          status: `success`,
          msg: result.msg,
        })
      }
    })
    .catch(err => {
      // Network failures, timeouts, etc
      this.setState({
        status: `error`,
        msg: err,
      })
    })
  }

  _handleFormSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
        status: `sending`,
        msg: null,
      },
      // setState callback (subscribe email to MC)
      this._postEmailToMailchimp(this.state.email, {
        pathname: document.location.pathname,
      })
    )
  }

  render() {
    return (
      	<div className={this.props.className}  capturesize={this.props.capturesize}>
        	{this.state.status === `success` ? (
        	<div className="[ description  description--small ]">
				<p>Thank you! Youʼre awesome! Youʼll receive your confirmation email shortly.</p>
			</div>
        	) : (
        	<div>
				<form
				id="email-capture"
				method="post"
				target="_blank"
				noValidate
				>
          <div className="shell-flex-parent  margin-bottom--1">
            <div className="shell-flex-child">
              {this.props.capturesize === `small` ? (
                <input
                className="email  [ formfield  formfield__textfield  formfield__textfield--small ]  width--100"
                type="email"
                name="email"
                placeholder="Your Email Address"
                onChange={this._handleEmailChange}
                />
              ) : (
                <input
                className="email  [ formfield  formfield__textfield  formfield__textfield--big ]  width--100"
                type="email"
                name="email"
                placeholder="Your Email Address"
                onChange={this._handleEmailChange}
                />
              )}
            </div>
            <div className="shell-flex-child">
              {this.props.capturesize === `small` ? (
                <button
                className="submit  [ button  button--small-square ]"
                type="submit"
                onClick={this._handleFormSubmit}
                >
                Sign up
                </button>
              ) : (
                <button
                className="submit  [ button  button--big-square ]"
                type="submit"
                onClick={this._handleFormSubmit}
                >
                Sign up
                </button>	
              )}
          </div>
          {this.state.status === `error` && (
          <div
					className="[ description  description--small ] text--error"
                    dangerouslySetInnerHTML={{ __html: this.state.msg }}
                  />
			  	)}
          </div>
          
        </form>
			        <span className="[ description  description--smaller ]">Join <em className="text--highlight">over 22,000 readers</em> for a weekly email with stories about overcoming failures and making an impact in the world.</span>
          </div>
        )}
      </div>
    )
  }
}

export default EmailCaptureForm